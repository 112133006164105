
import { mapGetters, mapActions } from 'vuex'
import axios from '@/plugins/axios'

export default {
  components: {
    TopBar: () => import('@/components/Header/VHeader-top-bar'),
    HeaderElens: () => import('@/components/Header/Header'),
    NewFooterElens: () => import('@/components/new-Footer/NFooter'),
    Landing: () => import('@/components/format/landing'),
    Rules: () => import('@/components/Structure/Rules'),
    UserPage: () => import('@/components/page-user/UserLoginAndNew')
  },
  async asyncData({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    const { data } = await axios.ElensApi.get(`${route.fullPath}`)
    return data
  },
  data: () => ({
    bgColor: '#fff',
    txColor: '#000',
    hasTopbar: false,
    hasHome: true
  }),
  computed: {
    ...mapGetters({
      LoginOpen: 'login/modalOpen'
    })
  },
  methods: {
    ...mapActions({
      toggleModalOpen: 'login/AAmodalOpen'
    }),
  },
  head () {
    return {
      title: 'Lentes de contato com grau, coloridas e soluções - E-lens',
      meta: [
        {
          hid: `og:title`,
          property: 'og:title',
          content: `Lentes de contato com grau, coloridas e soluções - E-lens`
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://www.e-lens.com.br' + this.$route.path
        }
      ]
    }
  },
  created() {
    if (this.page_header.topbars) {
      for (const item of this.page_header.topbars) {
        if (item.status === true) {
          this.hasTopbar = true
        }
      }
    }
  },
  mounted() {
    this.toggleModalOpen(false)
    if (this.page_type === 'landing') {
      this.bgColor = this.page_structure.seo.edit_page.bg_color
      this.txColor = this.page_structure.seo.edit_page.tx_color
    }
    if (window.dataLayer === undefined || window.dataLayer !== undefined) {
      if (localStorage.adobe_analytics_launch_state === '0' || localStorage.adobe_analytics_launch_state === '1') {
        this.$gtm.push({
          event: 'AdobeAnalytics',
          adobeAnalytics: {
            pageType: `Home`,
            pageFirstLevel: `Home`
          }
        })
        if (localStorage.adobe_analytics_launch_state === '0') {
          localStorage.setItem('adobe_analytics_launch_state', 1)
        }
        if (localStorage.adobe_analytics_launch_state === '1') {
          localStorage.setItem('adobe_analytics_launch_state', 2)
        }
      }
    }
    // setTimeout(() => {
    //   $TB.hooks.fireEvent('pageChange', null, {'sendBeacon': true})
    // }, 5000)
  }
}
